@import "~bootstrap-icons/font/bootstrap-icons.css";

 :root {
    --lrw-dialog-default-bg: #ffffffde;
    --lrw-dialog-default-bg-filter: blur(10px); 
    --lrw-dialog-default-border-radius: 20px;
    --lrw-dialog-default-drop-shadow: 0px 20px 40px -6px rgba(0,0,0,0.6);
  } 


html {
    height: 100%;
    width: 100%;
    padding: 0;
}
body {
    height: 100%;
    width: 100%;
    padding: 0;    
    font-family: "open sans", sans-serif;
    background-color: #f9fafc !important;
    display: flex;
    flex-direction: column;
    min-height: 0;
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: default;
    font-size: 14px;
    background-image: url('assets/images/gradient2.png');
    background-attachment: fixed;
    background-size: cover;

    box-shadow: inset 0px 15px 15px -00px rgba(0,0,0,0.1);
    
}

a {
    text-decoration: none;
    color: rgb(13, 110, 253);
}

home
{
    height: 100% !important;
}


h2 {
    font-size: 16px;
    font-weight: bold;
    color: rgb(94, 108, 132);
    line-height: 40px;
}

h1 {
    font-size: 30px;
    color: #5e6c84;
    margin-top: 20px;
    margin-bottom: 10px;
}

.ui-section-header
{
    font-size: 14px;
    font-weight: bold;
}
.heading-light
{
    color: rgba(255,255,255,0.8) !important;
}

.link-light
{
    color: rgba(255,255,255,0.8);
    cursor:pointer;
}

.link-light a
{
    color: rgba(255,255,255,0.8);
    text-decoration: none;
}

.link-light:hover
{
    text-decoration: underline;
}

.form-control::placeholder
{
    color: #000;
    opacity: 0.3;

}

.noselect
{
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    
}
.reset-a, .reset-a:hover, .reset-a:visited, .reset-a:focus, .reset-a:active
{
    text-decoration: none;
    color: inherit;
    outline: 0;
    cursor: auto;
}

.hidden
{
    visibility: hidden;
}

.body.div {
    flex: 1;
    display: flex;
    flex-direction:  column;

}
app-root {
    flex: 1;
    display: flex;
    flex-direction:  column;
    min-height: 0;
}


.messageContainer {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    background: var(--lrw-dialog-default-bg);
    backdrop-filter: var(--lrw-dialog-default-bg-filter);
    padding: 40px;
    box-shadow: var(--lrw-dialog-default-drop-shadow);
}

.formContainer {
    margin-top:80px;
    max-width: 330px;
    margin-left: auto;
    margin-right: auto;
    background: var(--lrw-dialog-default-bg);
    padding: 40px;
    box-shadow: var(--lrw-dialog-default-drop-shadow);
    border-radius: var(--lrw-dialog-default-border-radius);
    backdrop-filter: var(--lrw-dialog-default-bg-filter);

}

.formContainer label {
    font-weight: normal;
}
.formContainer .submit {
    margin-top: 20px;
    margin-bottom: 10px;
}

.formContainer form {
    margin-top: 20px;
}

.formContainer input[type="text"], input[type="password"]
{
    border-width: 1px;
}

.formContainer button
{
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.4);    
}

.formContainer .invalid {
    color: #d50000;
}

.formContainer .invalid input[type="text"]
{
    border-color: #d50000 !important;
}

.formContainer .invalid input[type="password"]
{
    border-color: #d50000 !important;
}


.usericon-small {
    height: 30px;   
}

.datatable-header-cell {
    border-right: 1px solid rgba(0,0,0,0.25);
}

resize-handle {
    visibility: visible;
}

datatable-body-row.active .datatable-row-group {
    background: #7075E6;
}

view-project
{
}

lrw-projectnav
{
    height: 100%;
}
.dataGridWrapper
{
    flex: 1;
    display: flex;
    min-height: 0;
    flex-direction: column;
}

.papersView.projectPage
{
    min-height: 100% !important;
    height: auto !important;
}

.projectPage {
    height: 100% !important;
    background: #f9fafc;
}

.projectViewMainContainer > as-split > as-split-gutter
{
    background: #f9fafc !important;
}



.as-split-gutter {
    background-image: none !important;
    background: transparent !important;
    border: none;
    position: relative;
}

.as-split-gutter[aria-orientation="vertical"]::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 7px;
    z-index: 9;
    transform: translateY(-50%);
}

.as-split-gutter[aria-orientation="horizontal"]::before {
    content: '';
    position: absolute;
    width: 7px;
    height: 100%;
    z-index: 9;
    transform: translateX(-50%);
}

.as-split-gutter:hover:before {
    background-color: #79cbff !important;
}


::-webkit-scrollbar-corner {
    background: #eee;
}


/* Boostrap btn-xs (dropped in bootstrap 4) */
.btn-xs
{
    padding: 1px 5px !important;
    font-size: 12px !important;
    line-height: 1.5 !important;
    border-radius: 3px !important;
}
.button-hard-left-corners {
    border-radius: 0px 3px 3px 0px;
}


/* Path Filter */
.angular-tree-component {
    cursor: default;
}
    
tree-node-wrapper {
    white-space: nowrap;
}
node-content-wrapper {
    white-space: nowrap;
}
.node-wrapper
{
    font-size: 12px;
    height: 19px;
}
tree-node-content {
    white-space: nowrap;
    display: flex;
}
tree-node-content span {
    white-space: nowrap;
    margin-left: 5px;
}
tree-viewport {
    display: flex;
    overflow: hidden;
}
tree-node-expander {
    width: 16px;
    height: 16px;
    cursor: pointer;
}

tree-node-expander > .toggle-children-wrapper > .toggle-children
{
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAABZSURBVChTY4ABRgam20C8EYhFoULYAVBBERD/BymGCmEHQAWsUMVfgXg5EAtApbADoIIMIAaZvIYYxbVQxcuhQgxMUJp0ADQFv9VAQeI8A1VEVPDgCXAGBgAX/BKbnG6geQAAAABJRU5ErkJggg==') !important;
    width: 10px;
    height: 10px;
    opacity: 0.4;
}

.tree-children
{
    border-left: 1px dashed rgba(0,0,0,0.15) !important;
    margin-left: 5px !important;
    padding-left: 15px !important;
}







/* Checkboxes */
.lrwCheckbox 
{
    position:relative;
    background: #f9fafc;
    width: 14px;
    height: 14px;
    box-shadow: 0px 0px 0px 1px rgba(0,0,0,0.2);
    font-size: 14px;
    line-height: 14px;
    margin-right: 5px;
    top: 3px;
    color: #007ACC;
}



/* Drop-Shadow PDF viewer */
lrw-item-viewer .ng2-pdf-viewer-container {
    overflow: visible !important;
}
lrw-item-viewer .pdfViewer .page {
    margin: 0px !important;
    margin-top: 1px !important;
    margin-bottom: 3px !important;
    box-shadow: 0px 0px 0px 1px rgba(0,0,0,0.2), 0px 3px 6px 0px rgba(0,0,0,0.6);
}
lrw-item-viewer pdf-viewer {
    display: block;
    height: calc(100% - 5px);
    width: calc(100% - 12px);
    margin-left: 6px;
    margin-top: 2px;
    top: 1px;
}

lrw-item-viewer pdf-viewer .textLayer
{
    opacity: 1 !important;
    mix-blend-mode: multiply !important;
}

lrw-item-viewer pdf-viewer .textLayer .highlight
{
    background-color: #fff590 !important;
    padding: 3px !important;
    margin-left: -3px !important;
    mix-blend-mode: multiply !important;
}

lrw-item-viewer pdf-viewer .textLayer .highlight.selected
{
    background-color: #fff590 !important;
    padding: 3px !important;
    margin-left: -3px !important;
    mix-blend-mode: multiply !important;
}

lrw-item-viewer pdf-viewer .textLayer ::selection
{
    color: transparent;
    background: #c8c8fffe;
}

/* Filter history tooltip */
.filterHistoryTooltip .tooltip-inner{
    color: #333 !important;
    background: #f9fafc !important;
    border: 1px solid rgba(0,0,0,0.4) !important;
    box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.4) !important;
    max-width: 999999px;
    text-align: left;
}
.filterHistoryTooltip .tooltip-inner pre{
    background: none;
    border: none;
}
.filterHistoryTooltip.tooltip.in {
    opacity: 1;
    filter: none;
}


/* Datagrid unfocused */
.focused .itemTableRow:nth-child(odd).subPrincipal .itemTableCellContainer:not(:first-child)  {
    background: #c8d7ed !important;
}
.focused .itemTableRow:nth-child(even).subPrincipal .itemTableCellContainer:not(:first-child) {
    background: #bed1e9 !important;
}
.unfocused .itemTableRow:nth-child(odd).subPrincipal .itemTableCellContainer:not(:first-child)  {
    background: #dfdfdf !important;
}
.unfocused .itemTableRow:nth-child(even).subPrincipal .itemTableCellContainer:not(:first-child) {
    background: #d8d8d8 !important;
}

.focused div.itemTableRow.subPrincipal.principal div.itemTableCellContainer:not(:first-child) {
    background: #007ACC !important;
    color: #e4e4e4;
}
.unfocused div.itemTableRow.subPrincipal.principal div.itemTableCellContainer:not(:first-child) {
    background: #777 !important;
    color: #e4e4e4;
}

/* Datagrid Tag Scale */
svg.tagicon {
    transform: scale(0.85) rotate(45deg);
}


/* chips */
.chipText
{
    padding: 2px 0px 2px 10px
}

.chip {
    display: flex;
    flex-direction: row;
    margin: 2px;
    margin-bottom: 8px;
    background: #c7c5ff;
    border-radius: 99px;
}

.chip .bi-x
{
    font-size: 24px;
    line-height: 21px;
    cursor: pointer;
}
.chip .glyphicon-remove {
    color: transparent;
    cursor: pointer;
    margin-right: -8px;
    left: -5px;
    top: -4px;
}

.section-chips 
{
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
}

.section-chips:hover .chip .bi-x:hover {
    color: red;
}


/* d3 styles */

path.link {
  fill: none;
  stroke: #aaa;
  stroke-width: 2px;
  stroke-linejoin: round;
  stroke-linecap: square;
}
circle.dot {
    fill: #aaa;
    stroke: #aaa;
    stroke-width: 2px;
}



/* ngx popper */
.colourpickerPopper {
    background: #f9fafc;
    z-index: 3;
}

/* filter Panels */
.filterPanelHint {
    color: #777;
    font-size: 12px;
    white-space: nowrap;
    padding: 5px;
    font-style: italic;
    
}
.filterPanelControls {
    padding: 10px;
    font-size: 12.5px;
}

.filterHintInlineButton {
    border: 1px solid rgba(0,0,0,0.2);
    padding: 2px;
    color: #777;
    border-radius: 4px;
    min-width: 52px;
    background: #f9fafc;
}
.filterPanelHint div.dropdown {
    display: inline-block;
}

.filterPanelHint .glyphicon {
    font-size: 15px;
    vertical-align: middle;
    margin-left: 4px;
    color: #777;
}

.filterPanelHint .dropdown-menu img
{
    height: 19px;
    margin-right: 5px;
}



.filterButton
{
	background:linear-gradient(to bottom, #f9f9f9 5%, #e9e9e9 100%);
	background-color:#f9f9f9;
	border-radius:0px 6px 6px 0px;
	border:1px solid #cacaca;
	display:inline-block;
	cursor:pointer;
	color:#666666;
	font-family:Arial;
	font-size:15px;
	text-decoration:none;
	text-shadow:0px 1px 0px #ffffff;
    box-shadow: inset 0px 1px 0px 0px #ffffff, 0px 1px 0px 0px rgba(0,0,0,0.1);
}

.filterButton:hover
{
    border:1px solid #cacaca !important;
	background:linear-gradient(to bottom, #ededed 5%, #d7d7d7 100%) !important;
}

.filterButton:active {
    border:1px solid #cacaca !important;
    background: #d7d7d7 !important;
    transition-duration: 0s;
}



/*doceditor menu styling*/
.ProseMirror
{
    outline: none !important;
}
.menuicon 
{
    display: inline-block;
    line-height: 1;
    padding: 3px 7px;
    margin: 1px;
    cursor: pointer;
    text-align: center;
    min-width: 1.4em;
    font-size: 12px;
    color: black;
    opacity: 0.6;
    overflow: hidden;
}
.menuicon.withlabel object
{
    margin-right: 10px;
}

.menuicon object
{
    vertical-align: bottom;
}
.menuicon:hover 
{
    background: rgba(0,0,0,0.2);
}
#cmd-strong, #cmd-heading 
{ 
    font-weight: bold; 
}
#cmd-strong
{
    font-weight:bolder;
    font-family: serif;
    font-size: 17px;
}
#cmd-em
{
    font-style: italic;
    font-family: serif;
    font-size: 17px;
}
#cmd-underline
{
    text-decoration: underline;
    font-family: serif;
    font-size: 17px;
}
#cmd-strikethrough
{
    text-decoration: line-through;
    font-family: serif;
    font-size: 17px;    
}
.menuicon.inactive {
    opacity: 0.3;
    cursor: default;
}


/*Prosemirror document style */
lrw-paper-itemreference
{
    display: inline-flex; 
    position: relative;   
}

lrw-paper-itemreference:hover .detailpane
{
    visibility: visible;
}

.prosemirrordoc .selectedCell
{
    background: rgba(0,0,255,0.1) !important;
}

.prosemirrordoc .itemreference
{
    color: #6e6eff;
    cursor: pointer;
    user-select: none;
    border-radius: 5px;
    padding: 0px 2px;
}

.prosemirrordoc .lrw-itemreference-anchor {
    cursor: pointer;
}

.prosemirrordoc a
{
    color: #6e6eff;
    text-decoration: underline;
}
.prosemirrordoc 
{
    font-family: "Open Sans", "arial", "helvetica", sans-serif;
    -moz-user-select: text;
    -webkit-user-select: text;
    -ms-user-select: text;
    user-select: text;
}
.prosemirrordoc p
{
    font-size: 10pt;
}
.prosemirrordoc h1 { font-size: 27pt; }
.prosemirrordoc h2 { font-size: 22pt; }
.prosemirrordoc h3 { font-size: 18pt; }
.prosemirrordoc h4 { font-size: 13pt; }
.prosemirrordoc h5 { font-size: 10pt; }
.prosemirrordoc h6 { font-size: 9pt; }
.prosemirrordoc hr
{
    border: 0px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-top: 1px solid black;
}
.prosemirrordoc table {
    width: 100%;
}
.prosemirrordoc th,
.prosemirrordoc td
{
    vertical-align: top;
    padding: 6px;
    border-collapse: collapse;
    border: 1px solid #ddd;
}

/* TODO: DIRTY HACK! FIX IT! figure out how to style the td's so that they wrap when neccessary. */
.prosemirrordoc td:nth-child(4)
{
    word-break: break-word;
    overflow-wrap: anywhere;
    
}

.prosemirrordoc .nogridlines {
    border: 1px solid transparent;
}
.prosemirrordoc table:hover .nogridlines {
    border: 1px solid rgba(0,0,255,0.2);
    transition: border-color 0.35s;
}
.prosemirrordoc th.nogridlines:first-child,
.prosemirrordoc td.nogridlines:first-child
{
    padding-left: 0px;
}
.prosemirrordoc th.nogridlines:last-child,
.prosemirrordoc td.nogridlines:last-child
{
    padding-right: 0px;
}
.prosemirrordoc tr:last-child th.nogridlines,
.prosemirrordoc tr:last-child td.nogridlines
{
    padding-bottom: 0px;
}
.prosemirrordoc tr:first-child th.nogridlines,
.prosemirrordoc tr:first-child td.nogridlines
{
    padding-top: 0px;
}

.prosemirrordoc th > p:last-child,
.prosemirrordoc td > p:last-child
{
    margin-bottom: 0px;
}

.prosemirrordoc .documentreference
{
/*
    text-decoration: none;
    background: rgba(0,0,0,0.04);
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.3);
*/
    text-decoration: underline;
}


/* .prosemirrordoc tr:hover
{
    background: rgba(0,0,0,0.05);
} */



/* doceditor collab example styles */

/* Collab Example */
.subtle { color: #777 }

.comment { background-color: #ff8 }
.currentComment { background-color: #fe0 }

.commentList, .commentText {
  display: block;
  padding: 0;
  margin: 0;
}
.tooltip-wrapper {
  display: inline-block;
  position: relative;
  width: 0;
  overflow: visible;
  vertical-align: bottom;
}
.ProseMirror ul.commentList {
  font-family: sans-serif;
  font-size: 16px;
  width: 15em;
  position: absolute;
  top: calc(100% + 8px);
  left: -2em;
  color: black;
  background: #f9fafc;
  font-weight: normal;
  border: 1px solid #888;
  border-radius: 5px;
  z-index: 10;
  padding: 0;
}
ul.commentList::before {
  border: 5px solid #888;
  border-top-width: 0px;
  border-left-color: transparent;
  border-right-color: transparent;
  position: absolute;
  top: -5px;
  left: calc(2em - 6px);
  content: " ";
  height: 0;
  width: 0;
}
li.commentText {
  padding: 2px 20px 2px 5px;
  position: relative;
  pointer-events: auto;
  margin: 0;
}
li.commentText + li.commentText {
  border-top: 1px solid silver;
}
.commentDelete {
  position: absolute;
  right: 0;
  border: 0;
  font: inherit;
  display: inline;
  color: inherit;
  background: transparent;
  cursor: pointer;
}
.commentDelete:hover {
  color: #f88;
}


.linktooltip-wrapper {
  width: 0;
    height: 0;
    overflow: visible;
    display: inline-block;
    position: relative;
    vertical-align: text-bottom;
    top: 6px;
    z-index: 1;
}
.linktooltip-inner {
    background: #fffff8;
    width: 110px;
    padding: 10px 15px;
    border-radius: 5px;
    box-shadow: 0px 4px 8px -2px rgba(0,0,0,0.3), 0px 0px 0px 1px rgba(0,0,0,0.05);
    color: #7878d5;
}

.linktooltip-link {
    text-decoration: none !important;
    cursor: pointer !important;
}

/*
.doclist {
  z-index: 20;
  display: block;
  padding: 2px 2px;
  margin: 0;
  border: 1px solid silver;
  position: absolute;
  background: white;
  font-size: 90%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  max-height: 15em;
  overflow-y: auto;
}

.doclist li {
  display: block;
  padding: 1px 3px;
  margin: 0;
  cursor: pointer;
}

.doclist li:hover {
  background: #5ae;
  color: white;
}

.docinfo {
  position: relative;
  color: #555;
}

.ProseMirror-report {
  position: fixed;
  top: 0; right: 0; left: 0;
  border-width: 0;
  border-style: solid;
  border-bottom-width: 1px;
  padding: 3px 27px 5px;
  white-space: pre;
  z-index: 1000;
}

.ProseMirror-report-fail {
  background: rgb(255, 230, 230);
  border-color: rgb(200, 150, 150);
}

.ProseMirror-report-delay {
  background: rgb(255, 255, 200);
  border-color: rgb(200, 200, 120);
}
*/

.emailField
{
    text-align: center;
    padding-top: 20px;
}


.button-shadow {
   box-shadow:rgba(0, 0, 0, 0.18) 0px 2px 4px
}

.modal-content
{
    background: var(--lrw-dialog-default-bg);
    border-radius: var(--lrw-dialog-default-border-radius);
    box-shadow: var(--lrw-dialog-default-drop-shadow);
    border: none;
    backdrop-filter: var(--lrw-dialog-default-bg-filter);
}

.modal-header
{
    border: none;
}

.modal-footer
{
    border: none;
}



.dropdown-menu
{
    box-shadow: 0 4px 8px 3px rgba(0,0,0,.15),0 1px 3px rgba(0,0,0,.3);

}

.inset-input
{
    border: none;
    box-shadow: inset 0px 1px 2px 0px rgba(0,0,0,0.3);
}

.inset-picker .ng-select-container
{
    border: none !important;
    box-shadow: inset 0px 1px 2px 0px rgba(0,0,0,0.3);
}

.importModal .modal-dialog
{

    display: grid;

}

.importModal .modal-fullscreen .modal-content
{
    height: auto;
    width: auto;
    margin:80px;
    border-radius: var(--lrw-dialog-default-border-radius);
    border: var(--bs-modal-border-radius) !important;

}

.importModal modal-header
{
    display: flex;
    flex-direction: row;
}

.importModal modal-header h1
{
    flex: 1;
}

.importModal modal-header h4
{
    margin-right: 30px;
}

.importModal .modal-body
{
    flex: 1;
    padding: 0px;
    display: flex;
    flex-direction: column;
}

.importModal ngx-file-drop
{
    flex: 1;
    display: flex;
    flex-direction: column;
}

.importModal .ngx-file-drop__drop-zone
{
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100% !important;
    width: 100% !important;
    border-radius: 0px !important;
    border: 0px solid rgba(0,0,0,0.1) !important;
    background: rgba(0,0,0,0.05);
}

.importModal .ngx-file-drop__content
{
    height: 100% !important;
    display: flex;
    flex-direction: row;
    align-items: start !important;
}

.importModal .calculatingDroppedFiles
{
    margin-top: 100px;
}

.importModal .uploadIcon
{
    flex: 1;
    height: 100% !important;
    display: flex;
    flex-direction: column;
    color: rgba(0,0,0,0.5);
    text-align: center;
    font-size: 16px;
}
.importModal .uploadIcon .vertpos
{
    flex: 1;
    min-width: 300px;
}

.importModal .uploadIcon span
{
    margin: 10px;
}


.importModal .uploadIcon button
{
    width: 120px;
    margin: 10px auto;
}

.ngx-file-drop__drop-zone--over
{
    background: #ffff0061 !important;
}

.filesFlex
{
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    color: #666;
    background: rgba(255, 255, 255, 0.2);
}

.filesTableContainer
{
    height: calc(100% - 40px);
    overflow-y: scroll;
    margin-top: 20px;
    position: relative;
}

.importDropZone .filesTable
{
    color: #444;
    position: absolute;
    min-width: 500px;
    width: 100%;

}

.importDropZone .filesTable .bi-file-earmark
{
    margin-right: 5px;
}

.importDropZone .filesTable .filesEntry td
{
    padding: 2px 10px;
    font-size: 13px;
}

.importDropZone .filesTable .filesEntry td:nth-child(2)
{
    text-align: right;
}

.importDropZone .filesTable .filesEntry:nth-child(odd) td
{
    background: #ffffff63;
}

.droplogo
{
    font-size: 120px;
    color: rgba(0,0,0,0.2);
}

.droppedFilesSummary
{
    text-align: center;
}




/* Attribute values */
.display-timestamp-date
{
    margin-right: 10px;
}
.display-timestamp-date .separator
{
    opacity: 0.5;
}
.display-timestamp-time
{
    margin-right: 10px;
}
.display-timestamp-timezone
{

}

/* Uploader */
.uppy-Dashboard-inner
{
    width: revert !important;
    height: 100% !important;
}

.uppy-Dashboard
{
  height: 100% !important;
}

.DashboardContainer
{
  height: 100% !important;
}

.link
{
    font-size: 12.5px;
    cursor: pointer;
}