:host ::ng-deep .ng-select {
    padding-bottom: 1.25em;
  }
  :host ::ng-deep .ng-select.ng-select-disabled .ng-select-container:after {
    border-bottom-color: transparent;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
    background-size: 4px 1px;
    background-repeat: repeat-x;
  }
  :host ::ng-deep .ng-select.ng-select-disabled .ng-select-container .ng-value-container .ng-value {
    color: rgba(0, 0, 0, 0.38);
  }
  :host ::ng-deep .ng-select.ng-select-disabled .ng-select-container .ng-value-container .ng-placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
  :host ::ng-deep .ng-select.ng-select-disabled .ng-arrow-wrapper .ng-arrow, .ng-select.ng-select-disabled .ng-clear-wrapper {
    color: rgba(0, 0, 0, 0.38);
  }
  :host ::ng-deep .ng-select.ng-select-focused .ng-select-container:after {
    border-color: #3f51b5;
    border-width: 2px;
  }
  :host ::ng-deep .ng-select.ng-select-focused .ng-select-container .ng-value-container .ng-placeholder {
    transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
    color: #3f51b5;
  }
  :host ::ng-deep .ng-select.ng-select-focused .ng-select-container .ng-arrow-wrapper .ng-arrow {
    color: #3f51b5;
  }
  :host ::ng-deep .ng-select .ng-has-value .ng-placeholder, .ng-select.ng-select-filtered .ng-select-container .ng-placeholder {
    display: initial;
  }
  :host ::ng-deep .ng-select .ng-has-value .ng-placeholder, .ng-select.ng-select-opened .ng-placeholder {
    transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  }
  :host ::ng-deep .ng-select .ng-select-container {
    align-items: baseline;
    min-height: 51.5px;
  }
  :host ::ng-deep .ng-select .ng-select-container:after {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    content: '';
    width: 100%;
    bottom: 0;
    position: absolute;
    transition: border-color 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
  }
  :host ::ng-deep .ng-select .ng-select-container .ng-value-container {
    align-items: baseline;
    padding: 0.4375em 0;
    border-top: 0.84375em solid transparent;
  }
  :host ::ng-deep .ng-select .ng-select-container .ng-value-container .ng-placeholder {
    position: absolute;
    color: rgba(0, 0, 0, 0.54);
    transform-origin: 0 0;
    transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
  :host ::ng-deep .ng-select .ng-select-container .ng-value-container .ng-input {
    bottom: 0.4375em;
  }
  :host ::ng-deep .ng-select.ng-select-single .ng-select-container .ng-arrow-wrapper {
    align-self: flex-end;
    bottom: 9px;
  }
  :host ::ng-deep .ng-select.ng-select-single .ng-select-container .ng-clear-wrapper {
    align-self: flex-end;
    bottom: 7px;
  }
  :host ::ng-deep .ng-select.ng-select-multiple.ng-select-disabled .ng-select-container .ng-value-container .ng-value {
    background-color: rgba(0, 0, 0, 0.12);
    color: rgba(0, 0, 0, 0.26);
  }
  :host ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    background-color: #3f51b5;
    border-radius: 2px;
    color: #fff;
    padding: 2px 5px;
    margin: 0 0.4375em 0.4375em 0;
  }
  :host ::ng-deep [dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    margin: 0 0 0.4375em 0.4375em;
  }
  :host ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled {
    background-color: rgba(0, 0, 0, 0.12);
    color: rgba(0, 0, 0, 0.26);
  }
  :host ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
    font-size: 14px;
    font-weight: 500;
  }
  :host ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
    color: rgba(255, 255, 255, 0.54);
    padding-right: 5px;
  }
  :host ::ng-deep [dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
    padding-left: 5px;
    padding-right: 0;
  }
  :host ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
    color: rgba(255, 255, 255, 0.87);
  }
  :host ::ng-deep .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
    line-height: 1.375em;
  }
  :host ::ng-deep .ng-select.ng-select-multiple .ng-select-container.ng-has-value {
    align-items: center;
  }
  :host ::ng-deep .ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-value-container {
    padding-bottom: 0;
    padding-top: 0.1875em;
  }
  :host ::ng-deep .ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-clear-wrapper, .ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-arrow-wrapper {
    border-top: 0.84375em solid transparent;
  }
  :host ::ng-deep .ng-select .ng-clear-wrapper {
    color: rgba(0, 0, 0, 0.54);
  }
  :host ::ng-deep .ng-select .ng-clear-wrapper:hover {
    color: rgba(0, 0, 0, 0.87);
  }
  :host ::ng-deep .ng-select .ng-arrow-wrapper {
    bottom: 2px;
  }
  :host ::ng-deep .ng-select .ng-arrow-wrapper .ng-arrow {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid;
    margin: 0 4px;
    color: rgba(0, 0, 0, 0.54);
  }
  :host ::ng-deep .ng-select .ng-spinner-zone {
    top: 3px;
  }
  :host ::ng-deep .ng-dropdown-panel {
    background: #fff;
    left: 0;
  }
  :host ::ng-deep .ng-dropdown-panel.ng-select-bottom {
    top: calc(100% - 1.25em);
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }
  :host ::ng-deep .ng-dropdown-panel.ng-select-top {
    bottom: calc(100% - .84375em);
    box-shadow: 0 -5px 5px -3px rgba(0, 0, 0, 0.2), 0 -8px 10px 1px rgba(0, 0, 0, 0.14), 0 -3px 14px 2px rgba(0, 0, 0, 0.12);
  }
  :host ::ng-deep .ng-dropdown-panel.multiple .ng-option.selected {
    background: #fff;
  }
  :host ::ng-deep .ng-dropdown-panel.multiple .ng-option.marked {
    background: rgba(0, 0, 0, 0.04);
  }
  :host ::ng-deep .ng-dropdown-panel .ng-dropdown-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 0 16px;
    line-height: 3em;
    min-height: 3em;
  }
  :host ::ng-deep .ng-dropdown-panel .ng-dropdown-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    padding: 0 16px;
    line-height: 3em;
    min-height: 3em;
  }
  :host ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
    user-select: none;
    cursor: pointer;
    line-height: 3em;
    height: 3em;
    padding: 0 16px;
    color: rgba(0, 0, 0, 0.54);
    font-weight: 500;
  }
  :host ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked {
    background: rgba(0, 0, 0, 0.04);
  }
  :host ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled {
    cursor: default;
  }
  :host ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected {
    background: rgba(0, 0, 0, 0.12);
    color: #3f51b5;
  }
  :host ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    line-height: 3em;
    min-height: 3em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 16px;
    text-decoration: none;
    position: relative;
    color: rgba(0, 0, 0, 0.87);
    text-align: left;
  }
  :host ::ng-deep [dir="rtl"] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    text-align: right;
  }
  :host ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background: rgba(0, 0, 0, 0.04);
    color: rgba(0, 0, 0, 0.87);
  }
  :host ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
    background: rgba(0, 0, 0, 0.12);
    color: #3f51b5;
  }
  :host ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
    color: rgba(0, 0, 0, 0.38);
  }
  :host ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
    padding-left: 32px;
  }
  :host ::ng-deep [dir="rtl"] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
    padding-right: 32px;
    padding-left: 0;
  }
  :host ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
    padding-right: 5px;
    font-size: 80%;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.38);
  }
  :host ::ng-deep [dir="rtl"] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
    padding-left: 5px;
    padding-right: 0;
  }
  